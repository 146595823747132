import { Component, ViewChild } from '@angular/core';

import { DataStoreService } from 'src/app/services/data-store.service';
import { EmailDetails } from 'src/app/models/emailDetails';
import { IEmailSubscriber } from 'src/app/store/email/email-model';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.css']
})
export class ComingSoonComponent {
  @ViewChild('comingSoonForm') form: any;
  model: EmailDetails = {
    email: '',
    firstName: '',
    lastName: '',
    frequency: 'n/a'
  } as IEmailSubscriber;
  submitted = false;

  constructor(private dss: DataStoreService) { }

  onSubmit() {
    this.submitted = true;
    this.dss.subscribeEmail(this.model);
    this.form.reset();
  }

}
