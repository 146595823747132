import { Component, OnInit, OnDestroy } from '@angular/core';
import * as firebase from 'firebase/app';
import { FirebaseApp } from '@angular/fire';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'src/app/store/model';
import { UserActions } from 'src/app/store/user/user-actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'afimagegroup';

  constructor(private firebase: FirebaseApp,
              private ngRedux: NgRedux<IAppState>,
              private actions: UserActions) { }

  ngOnInit() {
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        // update state
        this.actions.signIn(user.email);
      }
    });
  }

  ngOnDestroy() {
    this.actions.signOut();
  }
}
