import { Component, OnInit, OnDestroy } from '@angular/core';
import { MzModalService } from 'ngx-materialize';
import { select } from '@angular-redux/store';

import { IBlogPost } from 'src/app/store/blog-post/blog-post-model';
import { DataStoreService } from 'src/app/services/data-store.service';
import { BlogPostActions } from 'src/app/store/blog-post/blog-post-actions';
import { ServicesModalComponent } from '../services-modal/services-modal.component';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent {
  @select(['blogPosts', 'featurePost']) post: IBlogPost;

  constructor(private dss: DataStoreService,
              private modalService: MzModalService,
              private actions: BlogPostActions) {
    this.dss.getBlogPosts().subscribe(posts => {
      this.actions.updatePosts(posts);
    });
  }

  openServicesModal() {
    this.modalService.open(ServicesModalComponent);
  }
}
