import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../model';
import { IEmailSubscriber } from './email-model';

@Injectable({
    providedIn: 'root'
})
export class EmailActions {
    constructor(private ngRedux: NgRedux<IAppState>) {}

    static UPDATE_SUBSCRIBER_LIST = 'UPDATE_SUBSCRIBER_LIST';

    updateSubscriberList(subscribers: Array<IEmailSubscriber>): void {
        this.ngRedux.dispatch({ type: EmailActions.UPDATE_SUBSCRIBER_LIST, payload: subscribers });
    }
}
