import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { BlogContentComponent } from './components/blog-content/blog-content.component';
import { EmailListComponent } from './components/email-list/email-list.component';

const routes: Routes = [
  { path: '', redirectTo: '/af-image/coming-soon', pathMatch: 'full' },
  { path: 'af-image', redirectTo: '/af-image/coming-soon', pathMatch: 'full' },
  { path: 'af-image/coming-soon', component: ComingSoonComponent },
  { path: 'af-image/main', component: MainPageComponent },
  { path: 'af-image/fashion', component: BlogDetailsComponent },
  { path: 'af-image/fashion/post', component: BlogContentComponent },
  { path: 'af-image/follow', component: MainPageComponent },
  { path: 'af-image/email-list', component: EmailListComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
