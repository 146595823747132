import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { DataStoreService } from '../../services/data-store.service';
import { PostContent } from '../../models/postContent';

@Component({
  selector: 'app-blog-form',
  templateUrl: './blog-form.component.html',
  styleUrls: ['./blog-form.component.css']
})
export class BlogFormComponent implements OnInit {
  @ViewChild('blogForm') form: any;
  model: PostContent = {
    title:  '',
    content: '',
    description: '',
    imgLink: ''
  } as PostContent;
  submitted = false;

  constructor(private dss: DataStoreService) { }

  ngOnInit() { }

  onSubmit() {
    this.submitted = true;
    this.dss.addPost(this.model);
    this.form.reset();
  }

  uploadImg(event: any) {
    const fileName = Math.random().toString(36).substring(2);
    const filePath = 'blog-images/' + fileName;
    let imgUploadTask$: Observable<any>;

    // call data store service and upload file to cloud storage
    imgUploadTask$ = this.dss.uploadImgToStorage(event, filePath);
    imgUploadTask$.subscribe(url => {
      this.model.imgLink = url;
    });
  }
}
