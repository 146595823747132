import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select } from '@angular-redux/store';

import { DataStoreService } from 'src/app/services/data-store.service';
import { EmailActions } from 'src/app/store/email/email-actions';
import { IEmailSubscriber } from 'src/app/store/email/email-model';

@Component({
  selector: 'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.css']
})
export class EmailListComponent implements OnInit {
  @select(['subscribers', 'subscribers']) subs: Array<IEmailSubscriber>;

  constructor(private dss: DataStoreService,
              private actions: EmailActions,
              private router: Router) { }

  ngOnInit() {
    this.dss.getEmailDetails().subscribe(subs => {
      this.actions.updateSubscriberList(subs);
    });
  }

}
