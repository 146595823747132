import { Component, OnInit } from '@angular/core';
import { MzModalService } from 'ngx-materialize';
import { select, NgRedux } from '@angular-redux/store';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { FollowModalComponent } from '../follow-modal/follow-modal.component';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { UserActions } from 'src/app/store/user/user-actions';
import { IAppState } from 'src/app/store/model';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  @select(['users', 'isAdmin']) isAdmin: boolean;

  constructor(private modalService: MzModalService,
              private ngRedux: NgRedux<IAppState>,
              private actions: UserActions,
              private aus: AuthenticationService) { }

  ngOnInit() {}

  openFollowModal() {
    this.modalService.open(FollowModalComponent);
  }

  openAdminModal() {
    this.modalService.open(LoginModalComponent);
  }

  logOut() {
    console.log('in logOut()');

    // firebase auth log out
    this.aus.logout();

    // update state store
    this.actions.signOut();
  }
}
