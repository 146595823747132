import { IEmailSubscriber, IEmailState } from './email-model';

export const initialEmailState: IEmailState = {
    subscribers: null
};

export const EmailReducer = function rootReducer(state: IEmailState = initialEmailState, action: any): IEmailState {
    switch (action.type) {
        case 'UPDATE_SUBSCRIBER_LIST':
            const subscribers = (action.payload as Array<IEmailSubscriber>);

            return Object.assign({}, state, { subscribers });
        default:
            return state;
    }
};
