import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../model';
import { AuthenticationService } from '../../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class UserActions {
    constructor(private ngRedux: NgRedux<IAppState>,
                private aus: AuthenticationService) {}

    static SIGN_IN = 'SIGN_IN';
    static SIGN_OUT = 'SIGN_OUT';

    // Basic Action
    signIn(username: string): void {
        this.ngRedux.dispatch({ type: UserActions.SIGN_IN, payload: username });
    }

    signOut(): void {
        this.ngRedux.dispatch({ type: UserActions.SIGN_OUT });
    }
}
