import { NgModule } from '@angular/core';
import { NgReduxModule, NgRedux, DevToolsExtension } from '@angular-redux/store';
import { createLogger } from 'redux-logger';

import { IAppState } from './model';
import { rootReducer } from './reducer';
import { initialUserState } from './user/user-reducer';
import { initialBlogPostState } from './blog-post/blog-post-reducer';
import { initialEmailState } from './email/email-reducer';

@NgModule({
    imports: [
        NgReduxModule
    ],
    declarations: [],
    providers: []
})
export class StoreModule {
    constructor(public store: NgRedux<IAppState>, devTools: DevToolsExtension) {
        const middleware = [
            createLogger()
        ];

        // Tell @angular-redux/store about our rootReducer and our initial state.
        // It will use this to create a redux store for us and wire up all the
        // events.
        store.configureStore(
            rootReducer,
            {users: initialUserState, blogPosts: initialBlogPostState, subscribers: initialEmailState},
            middleware,
            devTools.isEnabled() ? [devTools.enhancer()] : []
        );
    }
}
