import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../model';
import { IBlogPost } from './blog-post-model';

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class BlogPostActions {
    constructor(private ngRedux: NgRedux<IAppState>) {}

    static UPDATE_POSTS = 'UPDATE_POSTS';

    updatePosts(posts: Array<IBlogPost>): void {
        this.ngRedux.dispatch({ type: BlogPostActions.UPDATE_POSTS, payload: posts });
    }
}
