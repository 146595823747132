import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select } from '@angular-redux/store';

import { DataStoreService } from '../../services/data-store.service';
import { BlogPostActions } from 'src/app/store/blog-post/blog-post-actions';
import { IBlogPostState, IBlogPost } from 'src/app/store/blog-post/blog-post-model';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {
  @select(['users', 'isAdmin']) isAdmin: boolean;
  @select(['blogPosts', 'posts']) posts: Array<IBlogPostState>;

  constructor(private dss: DataStoreService,
              private actions: BlogPostActions,
              private router: Router) { }

  ngOnInit() {
    this.dss.getBlogPosts().subscribe(posts => {
      this.actions.updatePosts(posts);
    });
  }

  viewPost(post: IBlogPost) {
    // pass title to blog-content component
    this.router.navigateByUrl('/af-image/fashion/post',
                              { state: { title: post.title }});
  }

  deletePost(postId: string) {
    // use dss service to delete post
    this.dss.deletePost(postId);
  }
}
