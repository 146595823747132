import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-admin',
  templateUrl: './blog-admin.component.html',
  styleUrls: ['./blog-admin.component.css']
})
export class BlogAdminComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
