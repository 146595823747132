import { Component } from '@angular/core';
import { MzBaseModal } from 'ngx-materialize';
import { ViewChild } from '@angular/core';
import { NgRedux } from '@angular-redux/store';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoginDetails } from '../../models/loginDetails';
import { IAppState } from '../../store/model';
import { UserActions } from 'src/app/store/user/user-actions';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent extends MzBaseModal {
  @ViewChild('loginForm') form: any;
  model: LoginDetails = {
    email: '',
    password: ''
  } as LoginDetails;
  admin = false;
  submitted = false;

  constructor(private aus: AuthenticationService,
              private ngRedux: NgRedux<IAppState>,
              private actions: UserActions) {
    super();
  }

  onSubmit() {
    // clear existing user
    this.aus.logout();
    // attempt to authenticate
    this.aus.login(this.model);
    this.submitted = true;
    this.form.reset();
  }

}
