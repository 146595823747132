import { Injectable, Inject } from '@angular/core';
import * as firebase from 'firebase/app';

import { LoginDetails } from '../models/loginDetails';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authenticated = false;
  userLoggedIn = new Subject<boolean>();

  constructor() {}

  login(model: LoginDetails) {
    firebase.auth().signInWithEmailAndPassword(model.email, model.password).then(() => {
      this.authenticated = true;
    }).catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;

      if (errorCode === 'auth/wrong-password') {
        alert('Incorrect password.');
      } else {
        alert(errorMessage);
      }

      console.log(error);
    });
  }

  logout() {
    firebase.auth().signOut().then(() => {
      // sign-out successful
      console.log('User signed out');
    }).catch(error => {
      // error occured
      console.log('Error: ' + error);
    });
  }

  getUser(): any {
    return firebase.auth().currentUser;
  }
}
