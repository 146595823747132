import { Component, Inject } from '@angular/core';
import { MzBaseModal } from 'ngx-materialize';
import { ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { DataStoreService } from 'src/app/services/data-store.service';
import { EmailDetails } from '../../models/emailDetails';
import { IEmailSubscriber } from 'src/app/store/email/email-model';

@Component({
  selector: 'app-services-modal',
  templateUrl: './services-modal.component.html',
  styleUrls: ['./services-modal.component.css']
})
export class ServicesModalComponent extends MzBaseModal {
  @ViewChild('emailForm') form: any;
  @ViewChild('formspreeForm', { read: ElementRef }) formspreeForm: ElementRef;
  @ViewChild('formspreeName', { read: ElementRef }) formspreeName: ElementRef;
  @ViewChild('formspreeEmail', { read: ElementRef }) formspreeEmail: ElementRef;
  @ViewChild('formspreeService', { read: ElementRef }) formspreeService: ElementRef;
  model: EmailDetails = {
    email: '',
    firstName: '',
    lastName: '',
    serviceDetails: ''
  } as IEmailSubscriber;
  submitted = false;

  constructor(private dss: DataStoreService,
              @Inject(DOCUMENT) private doc: HTMLDocument) {
    super();
  }

  onSubmit() {
    this.submitted = true;
    this.formspreeName.nativeElement.value = this.model.firstName + ' ' + this.model.lastName;
    this.formspreeEmail.nativeElement.value = this.model.email;
    this.formspreeService.nativeElement.value = this.model.serviceDetails;
    this.formspreeForm.nativeElement.submit();
    this.form.reset();
  }
}
