import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { last, mergeMap } from 'rxjs/operators';

import { PostDetails } from '../models/postDetails';
import { PostContent } from '../models/postContent';
import { EmailDetails } from '../models/emailDetails';
import { IBlogPost } from '../store/blog-post/blog-post-model';
import { IEmailSubscriber } from '../store/email/email-model';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  itemsCollection: AngularFirestoreCollection<PostDetails>;
  blogPosts: Observable<Array<IBlogPost>>;
  emailSubscribers: Observable<Array<IEmailSubscriber>>;
  downloadURL$: Observable<string>;

  constructor(private db: AngularFirestore,
              private storage: AngularFireStorage) {
    this.blogPosts = this.db.collection('posts').valueChanges();
    this.emailSubscribers = this.db.collection('emails').valueChanges();
  }

  getBlogPosts() {
    return this.blogPosts;
  }

  getEmailDetails() {
    return this.emailSubscribers;
  }

  getPostByTitle(title: string): any {
    let postData: any;
    const docRef = this.db.collection('posts').doc(title);

    docRef.get().subscribe(data => {
      if (data.exists) {
        postData = data.data();
      } else {
        console.log('No such Document');
      }
    });

    const postObservable = new Observable(observer => {
      setTimeout(() => {
        observer.next(postData);
      }, 1000);
    });

    return postObservable;
  }

  addPost(model: PostContent) {
    const datePipe = new DatePipe('en-US');
    const myFormattedDate = datePipe.transform(new Date(), 'EEEE, MMMM d');

    this.db.collection('posts').doc(model.title).set({
      content: model.content,
      title: model.title,
      dateAdded: myFormattedDate,
      description: model.description,
      imgLink: model.imgLink,
    })
    .then(() => {
      console.log('Post successfully written!');
    })
    .catch(error => {
      console.error('Error writing document: ', error);
    });
  }

  deletePost(id: string) {
    this.db.collection('posts').doc(id).delete()
    .then(() => {
      console.log('Post successfully deleted!');
    })
    .catch(error => {
      console.error('Error removing document: ', error);
    });
  }

  subscribeEmail(model: EmailDetails) {
    this.db.collection('emails').doc(model.email).set({
      emailAddress: model.email,
      firstName: model.firstName,
      lastName: model.lastName,
      frequency: model.frequency
    })
    .then(() => {
      console.log('Email successfully subscribed!');
    })
    .catch(error => {
      console.error('Error subscribing email: ', error);
    });
  }

  uploadImgToStorage(event: any, filePath: string): Observable<any> {
    const file = event.target.files[0];
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    return task.snapshotChanges()
      .pipe(
        last(),
        mergeMap(_ => {
            return fileRef.getDownloadURL();
        })
      );
  }
}
