import { IBlogPost, IBlogPostState } from './blog-post-model';


export const initialBlogPostState: IBlogPostState = {
  posts: null,
  featurePost: null
};

export const BlogPostReducer =
  function rootReducer(state: IBlogPostState = initialBlogPostState, action: any): IBlogPostState {
    switch (action.type) {
      case 'UPDATE_POSTS':
        // need to grab most recent blog post details
        const posts = (action.payload as Array<IBlogPost>);

        return Object.assign({}, state, { posts, featurePost: posts[0] });
      default:
        return state;
    }
};
