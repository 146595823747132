import { combineReducers } from 'redux';
import { composeReducers, defaultFormReducer } from '@angular-redux/form';

import { UserReducer } from './user/user-reducer';
import { BlogPostReducer } from './blog-post/blog-post-reducer';
import { EmailReducer } from './email/email-reducer';

export const rootReducer = composeReducers(
    combineReducers({
        users: UserReducer,
        blogPosts: BlogPostReducer,
        subscribers: EmailReducer
    })
);
