import { IUser } from './user-model';

export const initialUserState: IUser = {
    username: '',
    isAdmin: false
};

export const UserReducer = function rootReducer(state: IUser = initialUserState, action: any): IUser {
    switch (action.type) {
        case 'SIGN_IN':
            return Object.assign({}, state, {
                isAdmin: true,
                username: action.payload
            });
        case 'SIGN_OUT':
            return Object.assign({}, state, {
                isAdmin: false,
                username: ''
            });
        default:
            return state;
    }
};
