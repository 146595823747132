import { Component } from '@angular/core';
import { MzBaseModal } from 'ngx-materialize';
import { ViewChild } from '@angular/core';

import { DataStoreService } from 'src/app/services/data-store.service';
import { EmailDetails } from '../../models/emailDetails';
import { IEmailSubscriber } from 'src/app/store/email/email-model';

@Component({
  selector: 'app-follow-modal',
  templateUrl: './follow-modal.component.html',
  styleUrls: ['./follow-modal.component.css']
})
export class FollowModalComponent extends MzBaseModal {
  @ViewChild('emailForm') form: any;
  model: EmailDetails = {
    email: '',
    firstName: '',
    lastName: '',
    frequency: ''
  } as IEmailSubscriber;
  submitted = false;

  constructor(private dss: DataStoreService) {
    super();
  }

  onSubmit() {
    this.submitted = true;
    this.dss.subscribeEmail(this.model);
    this.form.reset();
  }
}
