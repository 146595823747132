import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataStoreService } from '../../services/data-store.service';
import { PostContent } from '../../models/postContent';

@Component({
  selector: 'app-blog-content',
  templateUrl: './blog-content.component.html',
  styleUrls: ['./blog-content.component.css']
})
export class BlogContentComponent implements OnInit {
  postData: PostContent[];
  title: string;
  content: string;
  imgLink: string;

  constructor(private dss: DataStoreService,
              private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {title: string};

    this.title = state.title;
  }

  ngOnInit() {
    const postObservable = this.dss.getPostByTitle(this.title);

    postObservable.subscribe((postData: PostContent[]) => {
      this.postData = postData;
      this.content = this.postData[`content`];
      this.imgLink = this.postData[`imgLink`];
    });
  }
}
