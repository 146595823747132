import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { MzModalModule, MzButtonModule, MzDropdownModule, MzCollapsibleModule } from 'ngx-materialize';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { BlogContentComponent } from './components/blog-content/blog-content.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { BlogAdminComponent } from './components/blog-admin/blog-admin.component';
import { BlogFormComponent } from './components/blog-form/blog-form.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { FollowModalComponent } from './components/follow-modal/follow-modal.component';
import { DataStoreService } from './services/data-store.service';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { AuthenticationService } from './services/authentication.service';
import { StoreModule } from './store/store.module';
import { EmailListComponent } from './components/email-list/email-list.component';
import { ServicesModalComponent } from './components/services-modal/services-modal.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    BlogDetailsComponent,
    NavBarComponent,
    BlogContentComponent,
    BlogAdminComponent,
    BlogFormComponent,
    MainPageComponent,
    FollowModalComponent,
    LoginModalComponent,
    EmailListComponent,
    ServicesModalComponent,
    ComingSoonComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    FormsModule,
    MzModalModule,
    MzButtonModule,
    MzDropdownModule,
    MzCollapsibleModule,
    AngularFontAwesomeModule,
    HttpClientModule,
  ],
  providers: [
    DataStoreService,
    AuthenticationService,
    AngularFireAuth
  ],
  entryComponents: [
    FollowModalComponent,
    LoginModalComponent,
    ServicesModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
